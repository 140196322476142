import React from 'react';
import { Routes, Route } from "react-router-dom";
import { Home } from './page/Home';
import WelCome from './page/WelCome';

export default function Rotas() {
    return (
        <Routes>
            <Route path="/" element={<WelCome />} />
            <Route path="/:id/:tenant" element={<Home />} />
        </Routes>
    );
}
